import React from "react";
import Button from "../common/button";

function CodeWeekPaket({ text, paketLogo, challengeButtonLabel, children }) {
  return (
    <>
      <section className="content homepage">
        <div className="container-limit my-limit code-week paket-top-container">
          <img
            className="code-week paket-logo"
            src={paketLogo}
            alt="Logo for Paket 1"
          />
          <p className="font-sub-header">{text}</p>
        </div>
        {children}
        {challengeButtonLabel && (
          <div className="container-fluid home-wrapper text-center">
            <Button
              className="btn button-room-bold my-2"
              label={challengeButtonLabel}
            ></Button>
          </div>
        )}
      </section>
    </>
  );
}

export default CodeWeekPaket;
